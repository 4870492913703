import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import billingInfo from "../../manage/billing-info.vue";
import saleRefSearch from "../saleRefSearch.vue";
import store from "@/store/index.js";
export default {
  data: () => ({
    salesRefList: [],
    error: "",
    searchText: "",
    showLoader: false,
    teamList: [],
    usersList: [],
    opened: [],
    number: 0,
    expandedRow: null,
    totalOpen: 0,
    totalReceived: 0,
    totalProcessing: 0,
    salesRefSearch: false,
    totalTicketed: 0,
    totalAccounting: 0,
    totalClosed: 0,
    sale_ref: {},
    isTableExpanded1: false,
    isTableExpanded2: true,
    isTableExpanded3: false,
    isTableExpanded4: false,
    isTableExpanded5: false,
    isTableExpanded6: false,
    ClearSearch: false,
    grossProfitCount: {},
    all_sales: localStorage.getItem("all_sales") === "true" || false,
    callQueuePresence:
      parseInt(localStorage.getItem("call_queue_presence")) || 0,
    selectedSearchInput: {
      agent: true,
      sale_id: true,
      quotation_id: true,
      lead_id: true,
      sale_reference_id: true,
      client_name: true,
      passenger: true,
      pnr: true,
      acn: true,
      from: true,
      to: true,
    },
    lastQueryString: "",
  }),
  mixins: [paginationMixin],
  components: {
    "header-list": headerList,
    "billing-info": billingInfo,
    "saleRef-search": saleRefSearch,
  },
  watch: {
    all_sales(newValue, oldVal) {
      localStorage.setItem("all_sales", newValue);
      if (newValue !== oldVal) {
        this.totalOpen = 0;
        this.totalReceived = 0;
        this.totalTicketed = 0;
        this.totalClosed = 0;
      }
      this.getAll();
    },
  },
  computed: {
    isToggleSwitchPermission() {
      const permission = this.hasToggleSwitchPermission();
      return permission;
    },
    hasOpenSales() {
      return this.salesRefList.some(
        (sale) => sale.status === "Open" && this.totalOpen != 0
      );
    },
    hasReceivedSales() {
      return this.salesRefList.some(
        (sale) => sale.status === "Received" && this.totalReceived != 0
      );
    },
    hasTicketedSales() {
      return this.salesRefList.some(
        (sale) => sale.status === "Ticketed" && this.totalTicketed != 0
      );
    },
    hasClosedSales() {
      return this.salesRefList.some(
        (sale) => sale.status === "Closed" && this.totalClosed != 0
      );
    },
    user() {
      return this.$store.state.userDetail;
    },
  },
  methods: {
    refreshSaleList() {
      let _vm = this;
      _vm.getAll();
    },
    updateQueuePresence() {
      const newValue = this.callQueuePresence === 1 ? 0 : 1;
      this.axios
        .get("/update-queue-presence", {
          params: {
            presence: newValue,
          },
        })
        .then((response) => {
          this.callQueuePresence = newValue;
          localStorage.setItem("call_queue_presence", newValue);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openSaleRefSearch() {
      let _vm = this;
      _vm.salesRefSearch = true;
    },
    calculateTotalSellingPrice(financialPax) {
      if (!financialPax || !Array.isArray(financialPax)) return 0;

      let totalSellingPrice = 0;
      financialPax.forEach((pax) => {
        if (pax.selling_price) {
          totalSellingPrice += parseFloat(pax.selling_price);
        }
      });
      return totalSellingPrice;
    },
    calculateTotalOpenSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach((item) => {
        if (
          item.financial_informations &&
          item.financial_informations.financial_pax
        ) {
          totalSum += parseFloat(
            this.calculateTotalSellingPrice(
              item.financial_informations.financial_pax
            ).toFixed(2)
          );
        }
      });
      return totalSum.toFixed(2);
    },
    calculateTotalReceivedSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach((item) => {
        if (
          item.financial_informations &&
          item.financial_informations.financial_pax
        ) {
          totalSum += parseFloat(
            this.calculateTotalSellingPrice(
              item.financial_informations.financial_pax
            ).toFixed(2)
          );
        }
      });
      return totalSum.toFixed(2);
    },
    calculateTotalTicketedSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach((item) => {
        if (
          item.financial_informations &&
          item.financial_informations.financial_pax
        ) {
          totalSum += parseFloat(
            this.calculateTotalSellingPrice(
              item.financial_informations.financial_pax
            ).toFixed(2)
          );
        }
      });
      return totalSum.toFixed(2);
    },
    calculateTotalClosedSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach((item) => {
        if (
          item.financial_informations &&
          item.financial_informations.financial_pax
        ) {
          totalSum += parseFloat(
            this.calculateTotalSellingPrice(
              item.financial_informations.financial_pax
            ).toFixed(2)
          );
        }
      });
      return totalSum.toFixed(2);
    },
    // formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, "0");
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },
    hasToggleSwitchPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "sales-view-all-sales";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    toggleTable(tableNumber) {
      if (tableNumber === 0) {
        this.isTableExpanded1 = !this.isTableExpanded1;
      } else if (tableNumber === 1) {
        this.isTableExpanded2 = !this.isTableExpanded2;
      } else if (tableNumber === 3) {
        this.isTableExpanded4 = !this.isTableExpanded4;
      } else if (tableNumber === 5) {
        this.isTableExpanded6 = !this.isTableExpanded6;
      }
    },
    toggleOpen(index) {
      if (this.expandedRow === index) {
        this.expandedRow = null;
      } else {
        this.expandedRow = index;
      }
    },
    getNextPage(page) {
      this.$refs.paginate.selected = page;
      this.paginate.current_page = page;
      this.getAll();
    },
    // start sales details
    // toggle(id) {
    //   const index = this.opened.indexOf(id);
    //   if (index > -1) {
    //     this.opened.splice(index, 1);
    //   } else {
    //     this.opened = [id];
    //   }
    // },
    search() {
      this.getAll();
    },
    // getQueryString() {
    //   let queryString = "?all_sales=" + this.all_sales;

    //   return queryString;
    // },

    getTeam() {
      const _vm = this;
      this.axios
        .get("/team-details", _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data;
        })
        .catch(function () {});
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/user-list")
        .then(function (response) {
          _vm.usersList = response.data.data;
        })
        .catch(function () {});
    },

    handleSearch(selectedData) {
      let _vm = this;
      _vm.getAll(selectedData);
      _vm.salesRefSearch = false;
      _vm.ClearSearch = true;
    },

    Clear() {
      let _vm = this;
      _vm.lastQueryString = "";
      _vm.all_sales = localStorage.getItem("all_sales") === "true" || false;
      _vm.getAll();
      _vm.ClearSearch = false;
    },

    getAll(selectedData = {}) {
      let _vm = this;
      store.state.isLoaderShow = true;
      if (Object.keys(selectedData).length === 0 && this.lastQueryString) {
        selectedData = this.lastQueryString;
      } else {
        this.lastQueryString = selectedData;
      }
      let queryString = this.getQueryString(selectedData);

      this.axios
        .get("/sales-by-references" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data;
          _vm.sale_ref = data;
          _vm.salesRefList = [];
          if (data.Open) {
            _vm.totalOpen = data.Open.total_sale_references;
            for (const key in data.Open.sale_references) {
              if (Object.hasOwnProperty.call(data.Open.sale_references, key)) {
                _vm.salesRefList.push(data.Open.sale_references[key]);
              }
            }
          }
          if (data.Received) {
            _vm.totalReceived = data.Received.total_sale_references;
            for (const key in data.Received.sale_references) {
              if (
                Object.hasOwnProperty.call(data.Received.sale_references, key)
              ) {
                _vm.salesRefList.push(data.Received.sale_references[key]);
              }
            }
          }

          if (data.Ticketed) {
            _vm.totalTicketed = data.Ticketed.total_sale_references;
            for (const key in data.Ticketed.sale_references) {
              if (
                Object.hasOwnProperty.call(data.Ticketed.sale_references, key)
              ) {
                _vm.salesRefList.push(data.Ticketed.sale_references[key]);
              }
            }
          }

          if (data.Closed) {
            _vm.totalClosed = data.Closed.total_sale_references;
            for (const key in data.Closed.sale_references) {
              if (
                Object.hasOwnProperty.call(data.Closed.sale_references, key)
              ) {
                _vm.salesRefList.push(data.Closed.sale_references[key]);
              }
            }
          }
          store.state.isLoaderShow = false;
          // Process data as before
        })
        .catch(function (error) {});
    },

    openNewTab(queue) {
      window.open("sales/" + queue, "_blank");
    },
    getQueryString(selectedData) {
      // Create query string with selected data
      let queryString = "?all_sales=" + this.all_sales;
      // Append selected data to the query string if available
      queryString += selectedData.agent_id
        ? "&agent=" + selectedData.agent_id
        : "";
      queryString += selectedData.section_id
        ? "&sale_id=" + selectedData.section_id
        : "";
      queryString += selectedData.Lead ? "&lead_id=" + selectedData.Lead : "";
      queryString += selectedData.quotation_id
        ? "&quotation_id=" + selectedData.quotation_id
        : "";
      queryString += selectedData.sale_reference_id
        ? "&sale_reference_id=" + selectedData.sale_reference_id
        : "";
      queryString += selectedData.Client_name
        ? "&client_name=" + selectedData.Client_name
        : "";
      queryString += selectedData.Passenger_name
        ? "&passenger_name=" + selectedData.Passenger_name
        : "";
      queryString += selectedData.pnr ? "&pnr=" + selectedData.pnr : "";
      queryString += selectedData.ACN ? "&acn=" + selectedData.ACN : "";
      queryString += selectedData.saleDateFrom
        ? "&from=" + selectedData.saleDateFrom
        : "";
      queryString += selectedData.saleDateTo
        ? "&to=" + selectedData.saleDateTo
        : "";
      return queryString;
    },
    assignedSales(status) {
      this.$router.push("sales/" + status);
    },
  },
  mounted() {
    this.getAll();
    this.getTeam();
    this.getUserList();
    this.callQueuePresence =
      parseInt(localStorage.getItem("call_queue_presence")) || 0;
  },
};
